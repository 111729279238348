// Modal.styled.tsx

import * as DialogPrimitive from "@radix-ui/react-dialog";
import styled from "styled-components";

export const ModalOverlay = styled(DialogPrimitive.Overlay)`
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999 !important; // Ensure it's above other elements
`;

export const ModalContent = styled(DialogPrimitive.Content)`
    position: fixed; // Change from 'relative' to 'fixed'
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000 !important; // 'z-index' may not need '!important'
    max-width: 90vw;
    max-height: 90vh;
    overflow: auto;
    /* Additional styles */
`;

export const ModalContainer = styled.div`
    /* Styles for your modal container */
`;
