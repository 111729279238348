/* eslint-disable indent */
import { FC } from "react";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import { Button } from "components/atoms/Button";
import { ButtonType } from "components/atoms/Button/types";
import { Modal } from "components/atoms/Modal";
import { UserChangelogViewDTO } from "types";
import { Span, SubTitle, Title } from "utils/styling/typography.styled";
import { ChangelogDescription, ChangelogImage, ChangelogItem, ChangelogList, Container, ModalContainer } from "./ChangelogModal.styled";
interface ChangelogModalProps {
  isOpen: boolean;
  changelogs: UserChangelogViewDTO[];
  onClose: () => void;
}
export const ChangelogModal: FC<ChangelogModalProps> = ({
  isOpen,
  changelogs,
  onClose
}) => {
  return <Modal open={isOpen} handleBackdropClick={onClose} disableBackdropClick data-sentry-element="Modal" data-sentry-component="ChangelogModal" data-sentry-source-file="index.tsx">
            <ModalContainer data-sentry-element="ModalContainer" data-sentry-source-file="index.tsx">
                <Title data-sentry-element="Title" data-sentry-source-file="index.tsx">Latest Updates</Title>
                <hr />
                <br />
                <ChangelogList data-sentry-element="ChangelogList" data-sentry-source-file="index.tsx">
                    {changelogs.map(changelog => <ChangelogItem key={changelog.changelogId}>
                            <SubTitle>{changelog.changelogTitle}</SubTitle>
                            <ChangelogDescription dangerouslySetInnerHTML={{
            __html: changelog.changelogDescription
          }} />
                            {changelog.changelogImageUrl && <>
                                    {changelog.changelogImageUrl.endsWith(".mp4") ? <ReactPlayer url={changelog.changelogImageUrl} width="100%" height="100%" playing controls={true} loop muted playsinline /> : changelog.changelogImageUrl.endsWith(".mp3") ? <div className="w-full h-full flex items-center justify-center">
                                            <ReactAudioPlayer src={changelog.changelogImageUrl} autoPlay controls loop />
                                        </div> : <ChangelogImage src={changelog.changelogImageUrl} alt={changelog.changelogTitle} width="400" height="400" layout="responsive" />}
                                </>}
                        </ChangelogItem>)}
                </ChangelogList>
            </ModalContainer>
            <Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
                <Button type="button" variant={ButtonType.PRIMARY} onClick={onClose} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                    <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Close</Span>
                </Button>
            </Container>
        </Modal>;
};