"use client";

import * as React from "react";
import { Edit, Puzzle, Sparkles, Upload, Video, Volume2Icon } from "lucide-react";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
export default function HeaderGenerateButton() {
  const [isOpen, setIsOpen] = React.useState(false);
  return <DropdownMenu open={isOpen} onOpenChange={setIsOpen} data-sentry-element="DropdownMenu" data-sentry-component="HeaderGenerateButton" data-sentry-source-file="index.tsx">
            <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="index.tsx">
                <Button className="bg-gradient-to-r from-emerald-500 to-emerald-600 hover:from-emerald-600 hover:to-emerald-700 text-white font-medium py-2 px-4 rounded-md shadow-md hover:shadow-lg transition-all duration-200 ease-in-out flex items-center space-x-2" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                    <Sparkles className="w-5 h-5" data-sentry-element="Sparkles" data-sentry-source-file="index.tsx" />
                    <span>Generate</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="!z-[99999] w-50 space-y-2" data-sentry-element="DropdownMenuContent" data-sentry-source-file="index.tsx">
                <DropdownMenuItem data-sentry-element="DropdownMenuItem" data-sentry-source-file="index.tsx">
                    <Link title="Generate Image" href="/generate" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <div className="flex space-4">
                            <Sparkles className="mr-2 h-4 w-4" data-sentry-element="Sparkles" data-sentry-source-file="index.tsx" />
                            <span>Generate Image</span>
                        </div>
                    </Link>
                </DropdownMenuItem>
                <DropdownMenuItem data-sentry-element="DropdownMenuItem" data-sentry-source-file="index.tsx">
                    <Link title="Generate Video" href="/generate?contentType=video" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <div className="flex">
                            <Video className="mr-2 h-4 w-4" data-sentry-element="Video" data-sentry-source-file="index.tsx" />
                            <span>Generate Video</span>
                        </div>
                    </Link>
                </DropdownMenuItem>
                <DropdownMenuItem data-sentry-element="DropdownMenuItem" data-sentry-source-file="index.tsx">
                    <Link title="Generate Audio" href="/generate?contentType=audio" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <div className="flex">
                            <Volume2Icon className="mr-2 h-4 w-4" data-sentry-element="Volume2Icon" data-sentry-source-file="index.tsx" />
                            <span>Generate Audio</span>
                        </div>
                    </Link>
                </DropdownMenuItem>
                <DropdownMenuItem data-sentry-element="DropdownMenuItem" data-sentry-source-file="index.tsx">
                    <Link title="Upload Image" href="/generate?formType=upload" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <div className="flex">
                            <Upload className="mr-2 h-4 w-4" data-sentry-element="Upload" data-sentry-source-file="index.tsx" />
                            <span>Upload Image</span>
                        </div>
                    </Link>
                </DropdownMenuItem>
                <DropdownMenuItem data-sentry-element="DropdownMenuItem" data-sentry-source-file="index.tsx">
                    <Link title="Edit Image" href="/edit" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <div className="flex">
                            <Edit className="mr-2 h-4 w-4" data-sentry-element="Edit" data-sentry-source-file="index.tsx" />
                            <span>Edit Image</span>
                        </div>
                    </Link>
                </DropdownMenuItem>
                <DropdownMenuItem data-sentry-element="DropdownMenuItem" data-sentry-source-file="index.tsx">
                    <Link title="Create Lora" href="/lora/create" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <div className="flex">
                            <Puzzle className="mr-2 h-4 w-4" data-sentry-element="Puzzle" data-sentry-source-file="index.tsx" />
                            <span>Create LORA</span>
                        </div>
                    </Link>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>;
}