import { UserPremiumType } from "types";

export const PREMIUM_COST = 10;
export const PREMIUM_THEME_COST = 1;
export const VIDEO_COST = 25;

export const MODEL_COSTS: { [key: string]: number } = {
    "Amazon Nova Canvas": 6,
    Anime: 1,
    Artistic: 1,
    Atomix: 1,
    AuraFlow: 3,
    Cheyenne: 1,
    Default: 1,
    "Dream Diffusion Lightning": 2,
    "Dreamshaper Lightning": 1,
    "Dream Tech XL": 2,
    "Envy Starlight XL": 1,
    "Flux.Dev": 1,
    "Flux.Dev Canny": 3,
    "Flux.Dev Depth": 3,
    "Flux.Dev Redux": 3,
    "Flux.Pro": 6,
    "Flux.Pro Canny": 7,
    "Flux.Pro Depth": 7,
    "Flux.Pro Fill": 7,
    "Flux.Pro Redux": 7,
    "Flux.Pro (Ultra)": 8,
    "Flux.Pro (Ultra) Redux": 7,
    "Flux.Schnell": 1,
    "Flux Subject": 4,
    "Ideogram 2.0": 7,
    "Juggernaut XII": 3,
    "Juggernaut XL": 1,
    "Illustration Ghibli": 1,
    "Luma Photon": 4,
    "Luma Photon Flash": 1,
    Niji: 1,
    "OmniGen V1": 12,
    "Playground V2.5": 1,
    RealVisXL: 2,
    "Recraft 20B": 2,
    "Recraft V3": 5,
    Sana: 1,
    SDXL: 1,
    "SD 3": 5,
    "Stable Diffusion 3.5 Large Base": 8,
    "Stable Diffusion 3.5 Large Turbo": 3,
    "Stable Diffusion 3.5 Medium": 4,
    "Zavy Chroma XL": 1,

    //Inpainting Costs
    Bria: 4,

    //Video Models
    "Luma Dream Machine": 50,
    "CogVideoX-5B": 20,
    "Kling 1.6 (Pro)": 20,
    "Kling 1.6 (Standard)": 3,
    "Kling 1.5 (Pro)": 20,
    "Kling 1.0 (Standard)": 4,
    "Haiper 2.0": 4,
    "Haiper 2.5": 5,
    Hunyuan: 50,
    MiniMax: 50,
    "MiniMax Video 01 Live": 50,
    "MiniMax Video 01 Subject Reference": 50,
    "Mochi 1": 40,
    "Runway Gen3 Alpha": 25,
    "LTX Video": 10,

    //Audio Models
    "MMAudio V2": 1,
};

export const SIZE_COSTS: { [key: string]: number } = {
    "1024x1024": 1,
    "896x1152": 1,
    "832x1216": 1,
    "768x1344": 1,
    "640x1536": 1,
    "1536x640": 1,
    "1344x768": 1,
    "1216x832": 1,
    "1152x896": 1,
    "1024x1792": 2, // Premium size
    "1792x1024": 2, // Premium size
};

export const calculateModelCost = (
    model: string | null | undefined,
    subscriptionType: UserPremiumType
) => {
    if (
        model === "Flux.Dev" &&
        (subscriptionType === UserPremiumType.ELITE ||
            subscriptionType === UserPremiumType.ULTIMATE)
    ) {
        return 0; // No cost for Flux.Dev for ELITE or ULTIMATE users
    }
    return model ? MODEL_COSTS[model] || 0 : 0;
};

export const calculatePremiumCost = (premium: boolean) =>
    premium ? PREMIUM_COST : 0;

export const calculateSizeCost = (size: string | null, premium: boolean) => {
    if (premium) {
        if (size === "1024x1792" || size === "1792x1024") {
            return SIZE_COSTS[size] || 1;
        }
        return SIZE_COSTS["1024x1024"] || 1;
    } else {
        if (size !== null) {
            return SIZE_COSTS[size] || 1;
        }
        return 1;
    }
};

export const calculateSizeCostForSana = (
    size: string | null,
    premium: boolean
) => {
    if (premium) {
        if (size === "1024x1792" || size === "1792x1024") {
            return SIZE_COSTS[size] || 1;
        }
        return SIZE_COSTS["1024x1024"] || 1;
    } else {
        if (size !== null) {
            const [width, height] = size.split("x").map(Number);
            const megapixels = Math.round(
                Math.min((width * height) / 1000000, 4096)
            );
            return megapixels;
        }
        return 1;
    }
};

export const calculatePremiumThemeCost = (
    premium: boolean,
    premiumTheme: boolean
) => (premiumTheme && !premium ? PREMIUM_THEME_COST : 0);

export const calculateVideoCost = (video: boolean) => (video ? VIDEO_COST : 0);

export const calculateNumStepsCost = (numSteps: number) =>
    numSteps > 0 ? Math.ceil((numSteps / 10) * 0.5) : 0;

export const calculateTotalGoldCost = (
    model: string | null,
    premium: boolean,
    aspectRatio: string | null,
    premiumTheme: boolean,
    numSteps: number,
    numImages: number,
    subscriptionType: UserPremiumType,
    lora: boolean
) => {
    let totalGoldCost = 1;

    if (!premium) {
        totalGoldCost = calculateModelCost(model, subscriptionType);
    }

    if (premium) {
        totalGoldCost = PREMIUM_COST;
    }

    totalGoldCost *= calculateSizeCost(aspectRatio, premium);
    if (model === "Sana" && aspectRatio) {
        const [width, height] = aspectRatio.split("x").map(Number);
        const megapixels = Math.round(
            Math.min((width * height) / 1000000, 4096)
        );
        totalGoldCost *= megapixels;
    }

    totalGoldCost *= numImages > 0 ? numImages : 1;

    if (
        model === "Flux.Pro" ||
        model === "Flux.Pro Canny" ||
        model === "Flux.Pro Depth" ||
        model === "Flux.Pro (Ultra)"
    ) {
        if (lora) {
            totalGoldCost += numImages > 0 ? numImages : 1;
        }
    }

    return totalGoldCost;
};

export const calculateTotalGoldCostForAudio = (
    model: string | null,
    audioType: string,
    duration: number | null
) => {
    let totalGoldCost = 1;

    totalGoldCost = calculateModelCost(model, UserPremiumType.NONE);

    if (model === "MMAudio V2" && duration) {
        totalGoldCost *= Math.ceil(duration / 10);
    }

    if (audioType === "tts") {
        totalGoldCost = 2;
    }

    return totalGoldCost;
};

export const calculateTotalGoldCostForVideo = (
    model: string | null,
    aspectRatio: string | null,
    numImages: number,
    proMode: boolean
) => {
    let totalGoldCost = 1;

    totalGoldCost = calculateModelCost(model, UserPremiumType.NONE);

    totalGoldCost *= calculateSizeCost(aspectRatio, false);
    if (model === "Sana" && aspectRatio) {
        const [width, height] = aspectRatio.split("x").map(Number);
        const megapixels = Math.round(
            Math.min((width * height) / 1000000, 4096)
        );
        totalGoldCost *= megapixels;
    }

    totalGoldCost *= numImages > 0 ? numImages : 1;

    if (proMode && model === "Hunyuan") {
        totalGoldCost *= 2;
    }

    return totalGoldCost;
};
