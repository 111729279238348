import * as React from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import { cn } from "@/lib/utils";
import { colourScheme } from "@/utils/styling/colours";
const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>>(({
  className,
  ...props
}, ref) => <SwitchPrimitives.Root className={cn("peer inline-flex h-5 w-9 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50", className)} style={{
  backgroundColor: props.checked ? colourScheme.secondary : colourScheme.primaryLight // Dynamically set background color
}} {...props} ref={ref}>
        <SwitchPrimitives.Thumb className={cn("pointer-events-none block h-4 w-4 rounded-full bg-white shadow-lg ring-0 transition-transform", props.checked ? "translate-x-4" : "translate-x-0")} />
    </SwitchPrimitives.Root>);
Switch.displayName = SwitchPrimitives.Root.displayName;
export { Switch };